import consumer from "./consumer"

window.ChatMessageChannel = consumer.subscriptions.create({channel: "ChatMessageChannel", room: 'hoge'}, {
    initialized() {
        this.is_connected = false;
    },
    connected() {
        // Called when the subscription is ready for use on the server
        console.log('connected');
        if (this.is_connected) {
            if (this.vue) this.vue.reconnected();
            else location.reload();
        }
        this.is_connected = true;
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
        console.log('disconnected');
    },

    received(data) {
        this.vue.receiveMessage(data);
        // Called when there's incoming data on the websocket for this channel
    },

    speak: function (data) {
        return this.perform('speak', data);
    },
    setChatVue(vue) {
        this.vue = vue;
    }
});